import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

const useStyles = theme => ({
    header: {
      backgroundColor: '#fff',
    },
    headGrid: {
      height: theme.spacing(8),
    },
    logo: {
      height: theme.spacing(6),
    },
})

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { classes } = this.props;
        return (
            <header className={classes.header}>
              <Container maxWidth="md">
                <Grid container alignItems="center" className={classes.headGrid} spacing={1}>
                  <Grid item>
                    <Link to="/"><img alt="柠檬精" className={classes.logo} src={require('../static/images/lemonjing3.png')} /> </Link>
                  </Grid>
  
                  <Grid item>
                    <Link to="/" style={{ textDecoration: 'none' }} ><Button color="primary" size="small" variant="outlined">主页</Button></Link>
                  </Grid>
                </Grid>
              </Container>
            </header>
        );
    }
}
export default withStyles(useStyles)(Header);