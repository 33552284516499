import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Gamepad from '../components/Gamepad'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import * as Scroll from 'react-scroll';
import CanvasDraw from "react-canvas-draw";
import ReactGA from '../components/ReactGaWrapper';
import PublishIcon from '@material-ui/icons/Publish';
import TextAd from '../components/TextAd';

const style = theme => ({
  navbar: {
    position: 'sticky',
    bottom: 0,
    color: '#f6637b',
    zIndex: 51,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  navbarBtnGroup: {
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#fff'
  },
  active: {
    backgroundColor: "#86b5e3",
    color: '#fff',
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#86b5e3"
    }
  },
  appbar: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#86b5e3',
    color: '#fff',
    height: 50,
    zIndex: 50,
    maxWidth: 896,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    backgroundColor: '#f1ece1',
    position: 'relative'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  balance: {
    flexGrow: 1,
  },
  topLogo: {
  },
  topPaper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  bigAvatar: {
    width: 150,
    height: 150
  },
  largewords: {
    fontWeight: 'bold',
    color: '#83cdb9',
  },
  wordsWrapper: {
    textAlign: 'center',
    width: "24rem"
  },
  descWrapper: {
    width: "10rem"
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  snackGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // backgroundColor: '#83cdb9'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,0.75)',
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    margin: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  cardActions: {
    paddingTop: 0,
  },
  cardInput: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  drinksMedia: {
    paddingTop: '180%',
  },
  toysMedia: {
    paddingTop: '70%',
  },
  itemName: {
    fontSize: 18,
    color: '#f37957',
    fontWeight: 'bold'
  },
  price: {
    backgroundColor: 'rgba(255,255,255,0.95)',
    border: '2px dotted #83cdb9',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontSize: 16
  },
  areaTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#f6637b'
  },
  addButton: {
  },
  shopList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listPanel: {
    paddingTop: theme.spacing(2),
    minHeight: 500
  },
  emptyLabel: {
    color: 'grey',
    textAlign: 'center',
  },
  footerText: {
    fontSize: '80%',
    letterSpacing: '0.2em',
    margin: '0.2em',
  },
  smallText: {
    fontSize: '70%',
    letterSpacing: '0.2em',
    margin: '0.2em',
    color: '#6b6b6b'
  },
  desc: {
    fontSize: 14,
    letterSpacing: '0.2em',
    lineHeight: 2,
    textAlign: 'center',
    color: '#f6637b'
  },
  reality: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  realityText: {
    fontSize: 24,
    textAlign: 'center',
    letterSpacing: '0.5em',
    color: '#f6637b'
  },
  comic: {
    width: '100%',
    maxWidth: 350,
    margin: '20px auto'
  },
});


const theme = createMuiTheme({
  shadows: [
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
  ],
  palette: {
    primary: {
      main: '#86b5e3',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ffa000',
      contrastText: '#fff'
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 14,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
class ChildhoodStore extends React.Component {
  constructor(props) {
    super(props);
    const money = 100

    this.items = require('../static/childhood.json');
    const itemsNumTmp = {};
    Object.keys(this.items).forEach(name => {
      itemsNumTmp[name] = 0
    });
    this.state = {
      profitPerSec: 0,
      money: money,
      balance: money,
      itemsNum: itemsNumTmp,
      isDrawerOpen: false,
      count: 0,
      isFinished: false,
      isDialog: false
    };
    this.delay = 1000
  }

  resetItemsNum = () => {
    const itemsNumTmp = {};
    Object.keys(this.items).forEach(name => {
      itemsNumTmp[name] = 0
    });
    this.setState({
      balance: this.state.money,
      itemsNum: itemsNumTmp,
    })
  }

  componentDidMount() {
    document.title = "童年模拟器-柠檬精LEMONJING";
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  numberInCn = (num) => {
    let [zheng, ling] = num.toFixed(2).toString().split('.')
    const kuai = zheng === '0' ? '' : zheng + '块'
    const mao = ling.substr(0, 1) === '0' ? '' : ling.substr(0, 1) + '毛'
    const fen = ling.substr(1, 1) === '0' ? '' : ling.substr(1, 1) + '分'
    return kuai + mao + fen
  }
  onNumberChange = (target) => {
    const itemname = target.id;
    if (!target.value || target.value == 0) { // 值为空
      target.value = 0;
    } else if (target.value[0] == 0 || target.value[0] == '-') { // 值为负数
      target.value = target.value.substr(1)
    }
    let itemnumber = target.value;

    // 计算物品数量为输入值时的总余额
    let currBalance = this.state.balance - (itemnumber - this.state.itemsNum[itemname]) * this.items[itemname]['price'];

    if (currBalance < 0) {
      // 如果物品数量为输入值时的总余额小于0， 计算当前物品数量余额大于0的情况下的最大值
      let exceptcurr = this.state.balance + this.state.itemsNum[itemname] * this.items[itemname]['price'] // 如果不买当前物品，还剩多少钱
      itemnumber = Math.floor(exceptcurr / this.items[itemname]['price']);
      target.value = itemnumber;
      currBalance = exceptcurr - this.items[itemname]['price'] * itemnumber
    }

    this.setState({
      count: this.state.count + (itemnumber - this.state.itemsNum[itemname]),
      itemsNum: {
        ...this.state.itemsNum,
        [itemname]: itemnumber
      },
      balance: currBalance,
    })
  }

  handleCheat = () => {
    this.setState({ money: this.state.money + 10000, balance: this.state.balance + 10000, isDialog: true })
    ReactGA.event({
      category: 'childhood',
      action: 'cheat'
    })
  }

  toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ isDrawerOpen: open })
  }


  scrollToAnchor = (anchorId) => {
    if (anchorId) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorId);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) { anchorElement.scrollIntoView(); }
    }
  }

  handleFinish = () => {
    this.setState({ isFinished: !this.state.isFinished })
    setTimeout(() => {
      Scroll.scroller.scrollTo('desc', {
        duration: 500,
        smooth: true,
        offset: -50,
      })
    }, 100)
    ReactGA.event({
      category: 'childhood',
      action: 'finish shopping'
    })
  }

  handleUnfinish = () => {
    this.setState({ isFinished: !this.state.isFinished })
    setTimeout(() => {
      Scroll.scroller.scrollTo('snacks', {
        duration: 500,
        smooth: true,
        offset: -90,
      })
    }, 100)
  }
  render() {
    const { balance, itemsNum } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.root} >
        <ThemeProvider theme={theme}>
          <SwipeableDrawer
            anchor="right"
            open={this.state.isDrawerOpen}
            onClose={this.toggleDrawer(false)}
            onOpen={this.toggleDrawer(true)}
          >
            <Grid container justify="center">
              <List className={classes.shopList}>

                <ListSubheader component="div" id="nested-list-subheader">
                  我买的东西 - 童年模拟器(lemonjing.com)
                </ListSubheader>
                {
                  this.state.count === 0 ? <Typography className={classes.emptyLabel}>你还什么都没买</Typography> : null
                }
                {
                  Object.keys(this.items).map(name => (itemsNum[name] == 0 ? null :
                    <ListItem key={name}>
                      <ListItemAvatar>
                        <Avatar src={require(`../static/images/childhood/items/${name}.jpg`)}></Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={name} secondary={'数量: ' + itemsNum[name]} />
                      <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button size="small" onClick={e => {
                          const target = document.getElementById(name);
                          target.value = parseInt(document.getElementById(name).value) - 1;
                          this.onNumberChange(target)
                        }}>-</Button>
                        <Button size="small" onClick={e => {
                          const target = document.getElementById(name);
                          target.value = parseInt(document.getElementById(name).value) + 1;
                          this.onNumberChange(target)
                        }}>+</Button>
                      </ButtonGroup>
                    </ListItem>
                  ))
                }
              </List>
            </Grid>

          </SwipeableDrawer>
          <Dialog
            open={this.state.isDialog}
            onClose={() => { this.setState({ isDialog: false }) }}
            aria-labelledby="余额增加了一万元"
            aria-describedby="余额增加了一万元"
          >
            <DialogTitle id="alert-dialog-title">你是富二代</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                你爸爸给了你一万元零花钱
          </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { this.setState({ isDialog: false }) }} color="primary">
                好的
          </Button>
            </DialogActions>
          </Dialog>

          <main>
            <Container className={classes.container} maxWidth="md">
              <Scroll.Element name="gamepad" style={{ textAlign: 'center', fontSize: 0 }}>
                <div><img alt="童年模拟器" src={require('../static/images/childhood/monitor.png')} style={{ maxWidth: 350, width: '100%', margin: '0 auto' }} /></div>

                <Gamepad style={{ margin: 20 }} onSuccess={this.handleCheat} />
              </Scroll.Element>
              <Scroll.Element name="intro">
                <Grid container justify="center" alignItems="center" className={classes.topPaper}>
                  <Box className={classes.wordsWrapper}>
                    <Typography variant="h5" className={classes.largewords}>欢迎使用童年模拟器。</Typography>
                    <Typography className={classes.footerText}>由<a href="https://www.zhihu.com/people/qian-zhui-6">一位靓仔</a>制作</Typography>
                    <Typography className={classes.footerText}>受到<a href="https://www.weibo.com/u/6774131741">千禧bot</a> &amp; <a href="https://www.weibo.com/u/6434212347">1980s-2010s</a>的启发</Typography>
                    <br />
                    <Typography className={classes.footerText}>-</Typography>
                    <Typography>你从小学的课堂上醒来</Typography>
                    <div><img alt="阿衰醒来" src={require('../static/images/childhood/漫画1.jpg')} className={classes.comic} /></div>
                    <Typography>你告诉同桌，说做了一个好长好长的梦</Typography>
                    <div><img alt="樱桃小丸子课堂" src={require('../static/images/childhood/漫画2.jpg')} className={classes.comic} /></div>
                    <Typography>这时候老师突然点名向你提问</Typography>
                    <div><img alt="大雄的老师提问" src={require('../static/images/childhood/漫画3.jpg')} className={classes.comic} /></div>
                    <Typography>而你竟然给出了正确答案</Typography>
                    <div><img alt="蜡笔小新回答问题" src={require('../static/images/childhood/漫画4.jpg')} className={classes.comic} /></div>
                    <Typography>老师很高兴，给了你一百块</Typography>
                    <div><img alt="呆头的老师让你花光钱" src={require('../static/images/childhood/漫画5.jpg')} className={classes.comic} /></div>
                    <Typography>于是你来到小卖部</Typography>
                    <br/>
                    {/* <ins className="adsbygoogle"
                      style={{ display: 'inline-block', width: 300, height: 250, margin: '12px auto' }}
                      data-ad-client="ca-pub-5404682594654148"
                      data-ad-slot="3571794136">
                    </ins> */}
                    <TextAd></TextAd>
                  </Box>
                </Grid>
              </Scroll.Element>
            </Container>
            <div>
              <Grid container alignItems='center' justify='space-between' className={classes.appbar}>
                <Grid item>
                  <Typography variant="h6" className={classes.balance}>
                    {(balance !== 0) ? "你还剩" + this.numberInCn(balance) : "你没有钱"}
                  </Typography>
                </Grid>
                {(
                  this.state.isFinished ? null :
                    <Grid item>
                      <IconButton aria-label="cart" onClick={this.toggleDrawer(true)}>
                        <Badge badgeContent={this.state.count} color="secondary">
                          <ShoppingCartIcon />
                        </Badge>
                      </IconButton>
                      {/* <Button variant='outlined' color='secondary'>买了什么</Button> */}
                    </Grid>
                )}
              </Grid>
              {(!this.state.isFinished) ?
                <div>
                  <Container className={classes.container} maxWidth="md">
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <img alt="黄金矿工商店" src={require('../static/images/childhood/黄金矿工商店.jpg')} style={{ width: '100%', maxWidth: 500 }} />
                    </div>


                    <Typography className={classes.smallText}>(品牌、人物仅用于举例，与本站无关；价格仅供参考)</Typography>


                    <Scroll.Element name="snacks">
                      <Grid className={classes.snackGrid} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.areaTitle}>零食</Typography>
                        </Grid>
                        {
                          Object.keys(this.items).map(name => ((this.items[name]['type'] !== 'snacks') ? null :
                            <Grid item key={name} xs={6} sm={4} md={3}>
                              <Card className={classes.card}>
                                <CardMedia
                                  className={classes.cardMedia}
                                  image={require(`../static/images/childhood/items/${name}.jpg`)}
                                  title={name}
                                >
                                </CardMedia>
                                <CardContent className={classes.cardContent}>
                                  <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                      <Typography className={classes.itemName}>
                                        {name}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <span className={classes.price}>{this.numberInCn(this.items[name].price)}</span>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                  <TextField
                                    id={name}
                                    data-itemname={name}
                                    type="number"
                                    value={itemsNum[name]}
                                    className={classes.cardInput}
                                    margin="normal"
                                    min='0'
                                    step='1'
                                    onChange={(e) => this.onNumberChange(e.target)}
                                  />
                                  <Button id={name} className={classes.addButton} size="small" variant="contained" color="primary" disabled={(balance < this.items[name].price)}
                                    onClick={e => {
                                      const target = document.getElementById(name);
                                      target.value = parseInt(document.getElementById(name).value) + 1;
                                      this.onNumberChange(target)
                                    }}>
                                    买！
                                </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Scroll.Element>
                    <br />

                    <Scroll.Element name="ices">
                      <Grid className={classes.snackGrid} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.areaTitle}>冰柜</Typography>
                          <div><img alt="请节约用电" src={require('../static/images/childhood/save_energy.png')} style={{ width: '100%', maxWidth: 220 }} /></div>
                        </Grid>

                        {
                          Object.keys(this.items).map(name => ((this.items[name]['type'] !== 'ices') ? null :
                            <Grid item key={name} xs={6} sm={4} md={3}>
                              <Card className={classes.card}>
                                <CardMedia
                                  className={classes.cardMedia}
                                  image={require(`../static/images/childhood/items/${name}.jpg`)}
                                  title={name}
                                >
                                </CardMedia>
                                <CardContent className={classes.cardContent}>
                                  <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                      <Typography className={classes.itemName}>
                                        {name}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <span className={classes.price}>{this.numberInCn(this.items[name].price)}</span>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                  <TextField
                                    id={name}
                                    data-itemname={name}
                                    type="number"
                                    value={itemsNum[name]}
                                    className={classes.cardInput}
                                    margin="normal"
                                    min='0'
                                    step='1'
                                    onChange={(e) => this.onNumberChange(e.target)}
                                  />
                                  <Button id={name} className={classes.addButton} size="small" variant="contained" color="primary" disabled={(balance < this.items[name].price)}
                                    onClick={e => {
                                      const target = document.getElementById(name);
                                      target.value = parseInt(document.getElementById(name).value) + 1;
                                      this.onNumberChange(target)
                                    }}>
                                    买！
                        </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Scroll.Element>

                    <br />

                    <Scroll.Element name="drinks">
                      <Grid className={classes.snackGrid} container spacing={1}>
                        <Grid item xs={12}>
                          <Typography className={classes.areaTitle}>饮料</Typography>
                          <div><img alt="开瓶器" src={require('../static/images/childhood/cap_lifter.png')} style={{ width: '100%', maxWidth: 350 }} /></div>
                        </Grid>
                        {
                          Object.keys(this.items).map(name => ((this.items[name]['type'] !== 'drinks') ? null :
                            <Grid item key={name} xs={4} sm={3} md={2}>
                              <Card className={classes.card}>
                                <CardMedia
                                  className={classes.drinksMedia}
                                  image={require(`../static/images/childhood/items/${name}.jpg`)}
                                  title={name}
                                >
                                </CardMedia>
                                <CardContent className={classes.cardContent}>
                                  <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                      <Typography className={classes.itemName}>
                                        {name}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <span className={classes.price}>{this.numberInCn(this.items[name].price)}</span>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                  <TextField
                                    id={name}
                                    data-itemname={name}
                                    type="number"
                                    value={itemsNum[name]}
                                    className={classes.cardInput}
                                    margin="normal"
                                    min='0'
                                    step='1'
                                    onChange={(e) => this.onNumberChange(e.target)}
                                  />
                                  <Button id={name} className={classes.addButton} size="small" variant="contained" color="primary" disabled={(this.state.balance < this.items[name].price)}
                                    onClick={e => {
                                      const target = document.getElementById(name);
                                      target.value = parseInt(document.getElementById(name).value) + 1;
                                      this.onNumberChange(target)
                                    }}>
                                    买！
                        </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Scroll.Element>

                    <br />

                    <Scroll.Element name="studys">
                      <Typography className={classes.areaTitle}>文具</Typography>
                      <Typography>试写纸</Typography>
                      <CanvasDraw brushRadius={1} canvasWidth={320} canvasHeight={100} lazyRadius={0} brushColor='#000' />
                      <Grid className={classes.snackGrid} container spacing={1}>
                        {
                          Object.keys(this.items).map(name => ((this.items[name]['type'] !== 'schools') ? null :
                            <Grid item key={name} xs={6} sm={4} md={3}>
                              <Card className={classes.card}>
                                <CardMedia
                                  className={classes.cardMedia}
                                  image={require(`../static/images/childhood/items/${name}.jpg`)}
                                  title={name}
                                >
                                </CardMedia>
                                <CardContent className={classes.cardContent}>
                                  <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                      <Typography className={classes.itemName}>
                                        {name}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <span className={classes.price}>{this.numberInCn(this.items[name].price)}</span>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                  <TextField
                                    id={name}
                                    data-itemname={name}
                                    type="number"
                                    value={itemsNum[name]}
                                    className={classes.cardInput}
                                    margin="normal"
                                    min='0'
                                    step='1'
                                    onChange={(e) => this.onNumberChange(e.target)}
                                  />
                                  <Button id={name} className={classes.addButton} size="small" variant="contained" color="primary" disabled={(this.state.balance < this.items[name].price)}
                                    onClick={e => {
                                      const target = document.getElementById(name);
                                      target.value = parseInt(document.getElementById(name).value) + 1;
                                      this.onNumberChange(target)
                                    }}>
                                    买！
                        </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Scroll.Element>

                    <br />

                    <Scroll.Element name="toys">
                      <Grid className={classes.snackGrid} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.areaTitle}>玩具</Typography>
                        </Grid>
                        {
                          Object.keys(this.items).map(name => ((this.items[name]['type'] !== 'toys') ? null :
                            <Grid item key={name} xs={6} sm={4} md={3}>
                              <Card className={classes.card}>
                                <CardMedia
                                  className={classes.toysMedia}
                                  image={require(`../static/images/childhood/items/${name}.jpg`)}
                                  title={name}
                                >
                                </CardMedia>
                                <CardContent className={classes.cardContent}>
                                  <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                      <Typography className={classes.itemName}>
                                        {name}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <span className={classes.price}>{this.numberInCn(this.items[name].price)}</span>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                  <TextField
                                    id={name}
                                    data-itemname={name}
                                    type="number"
                                    value={itemsNum[name]}
                                    className={classes.cardInput}
                                    margin="normal"
                                    min='0'
                                    step='1'
                                    onChange={(e) => this.onNumberChange(e.target)}
                                  />
                                  <Button id={name} className={classes.addButton} size="small" variant="contained" color="primary" disabled={(balance < this.items[name].price)}
                                    onClick={e => {
                                      const target = document.getElementById(name);
                                      target.value = parseInt(document.getElementById(name).value) + 1;
                                      this.onNumberChange(target)
                                    }}>
                                    买！
                        </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Scroll.Element>

                    <br />

                    <Scroll.Element name="pay" style={{ textAlign: 'center' }}>
                      <Button variant="contained" color="secondary" onClick={this.handleFinish}>买完了，结账！</Button>
                    </Scroll.Element>
                  </Container>
                </div>
                :
                <div style={{ textAlign: 'center' }}>
                  <Scroll.Element name="desc">
                    {
                      this.state.count === 0 ? <Typography className={classes.desc}>你什么都没有买</Typography> : null
                    }
                    {
                      Object.keys(this.items).map(name => (itemsNum[name] == 0 ? null :
                        <div key={name} >
                          <Typography className={classes.desc}>
                            {this.items[name]['desc'].replace(/#num/g, itemsNum[name])}
                          </Typography>
                          <Avatar src={require(`../static/images/childhood/items/${name}.jpg`)} style={{ display: 'inline-block' }}></Avatar>
                        </div>
                      ))
                    }
                  </Scroll.Element>

                  {(balance !== 0) ? <Typography className={classes.desc}> 你兜里还剩 <b>{this.numberInCn(balance)}</b></Typography> :
                    <Typography className={classes.desc}>你把所有钱都花光了</Typography>}
                  <div style={{ fontSize: 0 }} >
                    <img alt="气泡" src={require('../static/images/childhood/气泡分割.svg')} style={{ width: '100%' }} />
                    <div className={classes.reality}>

                      <Typography className={classes.desc}>你从梦中醒来，回到现实</Typography>
                      <Typography>-</Typography>
                      <Typography variant="h5" className={classes.largewords}>感谢使用童年模拟器</Typography>
                      <Typography>-</Typography>
                    </div>
                  </div>
                </div>
              }

            </div>
          </main>
          <div style={this.state.isFinished ? { backgroundColor: '#fff', padding: 12 } : { padding: 12 }}>
            <Typography className={classes.desc} >
              <b>彩蛋</b> - 觉得100块不够用？
            </Typography>

            <Typography className={classes.desc} >
              页面顶部的<Scroll.Link to="gamepad" smooth={true} duration={500} offset={-90}>游戏手柄</Scroll.Link>不只是装饰品
          </Typography>
            <Typography className={classes.desc} >
              试试用它打出「魂斗罗的秘籍（作弊码）」
          </Typography>
          </div>
          <Grid container alignItems='center' justify='space-between' className={classes.navbar} style={this.state.isFinished ? { backgroundColor: '#fff' } : null}>

            {
              (!this.state.isFinished) ?
                <ButtonGroup color="primary" size='medium' className={classes.navbarBtnGroup} aria-label="outlined primary button group">
                  <Button onClick={Scroll.animateScroll.scrollToTop}><PublishIcon /></Button>
                  <Button component={Scroll.Link} activeClass={classes.active} to="snacks" spy={true} smooth={true} duration={500} offset={-50}>零食</Button>
                  <Button component={Scroll.Link} activeClass={classes.active} to="ices" spy={true} smooth={true} duration={500} offset={-50}>冰柜</Button>
                  <Button component={Scroll.Link} activeClass={classes.active} to="drinks" spy={true} smooth={true} duration={500} offset={-50}>饮料</Button>
                  <Button component={Scroll.Link} activeClass={classes.active} to="studys" spy={true} smooth={true} duration={500} offset={-50}>文具</Button>
                  <Button component={Scroll.Link} activeClass={classes.active} to="toys" spy={true} smooth={true} duration={500} offset={-50}>玩具</Button>
                  <Button onClick={Scroll.animateScroll.scrollToBottom}>结账</Button>
                </ButtonGroup> :
                <ButtonGroup variant="contained" color="secondary" className={classes.navbarBtnGroup} aria-label="outlined primary button group">
                  <Button onClick={this.handleUnfinish}>我想回去接着买</Button>
                </ButtonGroup>
            }
          </Grid>
          {/* <Container maxWidth='md'>
            <ins className="adsbygoogle"
              style={{ display: 'inline-block', width: '100%', height: 100, margin: '10px auto' }}
              data-ad-client="ca-pub-5404682594654148"
              data-ad-slot="5677084858">
            </ins>
          </Container> */}
        </ThemeProvider >
      </div >
    );
  }

}

export default withStyles(style)(ChildhoodStore);