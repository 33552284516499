import amber from '@material-ui/core/colors/amber';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const RichSimulatorStyle = theme => ({
  appbar: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    height: 65,
    zIndex: 5,
    maxWidth: 896,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0'
  },
  scrollUpButton: {
    position: 'fixed',
    right: -100,
    bottom: 20,
    transition: 'right 0.5s',
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    border: '2px solid ' + theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 20,
    padding: 5,
    zIndex: 51,
  },
  scrollUpTrans: {
    right: 20,
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  root: {
    flexGrow: 1,
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  balance: {
    flexGrow: 1,
  },
  topLogo: {
  },
  topPaper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  yuebaoPaper: {
    fontSize: '80%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  yuebaoLabel: {
    color: theme.palette.primary.main
  },
  bigAvatar: {
    width: 150,
    height: 150
  },
  largewords: {
    margin: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  wordsWrapper: {
    textAlign: 'center',
    width: "36rem"
  },
  descWrapper: {
    width: "10rem"
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    margin: 0,
    paddingBottom: 0,
    paddingTop: theme.spacing(1),
  },
  cardActions: {
    paddingTop: 0,
  },
  cardInput: {
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  price: {
    color: theme.palette.primary.dark
  },
  addButton: {
    width: 90,
    minWidth: 90
  },
  shopList: {
    width: '100%',
    maxWidth: 450,
    margin: '0 auto'
  },
  finishWrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    color: theme.palette.secondary.dark,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  listPanel: {
    paddingTop: theme.spacing(2),
    minHeight: 500
  },
  emptyLabel: {
    color: 'grey',
    textAlign: 'center',
  },
  smallText: {
    fontSize: '60%',
    letterSpacing: '0.2em',
    margin: '0.2em',
    color: '#6b6b6b'
  },
  qrcodeImg: {
    width: '3rem',
    margin: theme.spacing(1),
  },
  popText: {
    letterSpacing: '0.1em',
    fontSize: '70%',
    textAlign: 'center',
  },
  collectImg: {
    height: '20rem'
  },
  collectFrame: {
    height: '20rem'
  },
  orderCard: {
    display: 'flex',
  },
  orderMedia: {
    paddingLeft: 135
  },
  orderContent: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  paySuccess: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    margin: '0 auto',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  orderPaper: {
    padding: theme.spacing(1),
    backgroundColor: 'rgba(245, 245, 245, 0.7)'
  },
  linksWrapper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
  },
  paperTitle: {
      margin: 3,
  },
  paperContent: {
      padding: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.75)'
  },
});
const MySwitch = withStyles({
  switchBase: {
    color: amber[300],
    '&$checked': {
      color: amber[500],
    },
    '&$checked + $track': {
      backgroundColor: amber[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export { RichSimulatorStyle, MySwitch }