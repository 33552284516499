import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Container, Paper, Typography, Button } from '@material-ui/core';
import Reverser from '../components/Reverser'
import TextAd from '../components/TextAd';
// import Wavesurfer from 'react-wavesurfer';

// In my bundle config this is setup to export to window.WaveSurfer

const useStyles = theme => ({
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    introWrapper: {
        textAlign: "center",
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    text: {
        color: "rgba(0, 0, 0, 0.78)",
        lineHeight: 1.8
    },
    textSmall: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: theme.typography.fontSize,
        marginBottom: theme.spacing(1)
    },
    hint: {
        margin: theme.spacing(2)
    },
    currText: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.8
    },
    normalText: {
        fontSize: 14,
        color: "rgba(0, 0, 0, 0.54)",
        lineHeight: 1.8
    }
})

class ReverseChallenge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0
        };
        this.steps = [
            {
                leftEnable: true,
                rightEnable: false,
                leftReverseHighlight: true,
                leftFillText: "👆请  [🐶玩家1] 录制一个词语",
                rightFillText: "...",
            },
            {
                leftEnable: true,
                rightEnable: true,
                leftReverseHighlight: true,
                leftFillText: "似乎出了点问题😋请重新录制",
                rightFillText: "👆请  [🐱玩家2] 模仿倒放的音频",
            },
            {
                leftEnable: false,
                rightEnable: true,
                leftReverseHighlight: false,
                leftFillText: "似乎出了点问题😋请重新录制",
                rightFillText: "似乎出了点问题😋请重新录制",
            }
        ]
    }
    componentDidMount() {
        document.title = "倒放挑战，在线玩倒放挑战-柠檬精LEMONJING";
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    onRef = (ref, child) => {
        child = ref
    }

    handleRestart = () => {
        this.setState({ step: 0 })
        this.leftChild.restart()
        this.rightChild.restart()
    }

    handleLeftStatusChange = (isFinishRecord) => {
        if (isFinishRecord) {
            this.setState({ step: 1 })
        } else {
            this.setState({ step: 0 })
        }
    }
    handleRightStatusChange = (isFinishRecord) => {
        if (isFinishRecord) {
            this.setState({ step: 2 })
        } else {
            this.setState({ step: 1 })
        }
    }

    render() {
        const { classes } = this.props;
        const current = this.steps[this.state.step]
        return (
            <div>

                <Container className={classes.container} maxWidth="md">

                    <Paper className={classes.introWrapper}>
                        <Typography variant="h5" component="h2">倒放挑战</Typography>
                        <Typography className={classes.text}>在这里和朋友一起玩最近很火的倒放挑战！</Typography>
                        <Typography className={classes.textSmall}>本站绝不会保存任何您的个人信息。请允许录音权限。录音功能兼容性不佳，请在页面底部了解哪些浏览器能使用本站。</Typography>
                    </Paper>
                    
                    {/* <ins className="adsbygoogle"
                        style={{ display: 'inline-block', width: '100%', height: 100}}
                        data-ad-client="ca-pub-5404682594654148"
                        data-ad-slot="5414879916">
                    </ins>   */}
                    
                    <div className={classes.hint}>
                        <Typography className={this.state.step === 0 ? classes.currText : classes.normalText}>1. 请  [<span role="img" aria-label="狗">🐶</span>玩家1] 录制一个词语。其他玩家请捂住耳朵<span role="img" aria-label="捂耳朵">🙉</span>。</Typography>
                        <Typography className={this.state.step === 1 ? classes.currText : classes.normalText}>2. 播放  [<span role="img" aria-label="狗">🐶</span>玩家1] 的  [倒放▶] 。请  [<span role="img" aria-label="猫">🐱</span>玩家2] 模仿倒放的声音并录制。</Typography>
                        <Typography className={this.state.step === 2 ? classes.currText : classes.normalText}>3. 播放  [<span role="img" aria-label="猫">🐱</span>玩家2] 的  [倒放▶] 。请  [<span role="img" aria-label="猫">🐱</span>玩家2] 通过倒放猜测  [<span role="img" aria-label="狗">🐶</span>玩家1] 录制的词语。</Typography>
                        {current.text}<Button size="small" variant="contained" color="primary" onClick={this.handleRestart}>重新开始</Button>
                    </div>
                    
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={6}><Reverser
                            reverseHighlight={current.leftReverseHighlight}
                            onStatusChange={this.handleLeftStatusChange}
                            onRef={(ref) => this.leftChild = ref}
                            enable={current.leftEnable}
                            title="🐶玩家1"
                            fillText={current.leftFillText}></Reverser>
                        </Grid>
                        <Grid item xs={6}>
                            <Reverser
                                reverseHighlight={true}
                                onStatusChange={this.handleRightStatusChange}
                                onRef={(ref) => this.rightChild = ref}
                                enable={current.rightEnable}
                                title="🐱玩家2"
                                fillText={current.rightFillText}>
                            </Reverser></Grid>
                    </Grid>
                    <br />
                    <TextAd/>
                    <Typography className={classes.textSmall}><b>电脑端</b>：支持<a href="https://www.google.cn/intl/zh-CN/chrome/">Chrome</a>、<a href="https://www.firefox.com.cn/">火狐</a>、<a href="https://browser.360.cn/ee/">360</a>和<a href="https://browser.qq.com/">QQ</a>等多数浏览器，不支持Edge、IE和Safari。</Typography>
                    <Typography className={classes.textSmall}><b>安卓</b>：支持<a href="http://android.chrome.hk.cn/getAndroid/">Chrome</a>、<a href="https://download-ssl.firefox.com.cn/releases/mobile/68.3/zh-CN/Firefox-Android-68.3.apk">火狐</a>和三星浏览器，不支持大部分手机内置、微博内置、QQ和UC等多数浏览器。部分手机的微信内置浏览器能用。</Typography>
                    <Typography className={classes.textSmall}><b>苹果</b>：不支持所有浏览器。</Typography>
                    <Typography className={classes.textSmall}>用不了不怪我，都是浏览器的错。请下载支持的浏览器或在电脑端使用。</Typography>
                </Container>
            </div>
        );
    }
}
export default withStyles(useStyles)(ReverseChallenge);