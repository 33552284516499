import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./containers/Home";
import RichSimulator from "./containers/RichSimulator";
import Terms from "./containers/Terms"
import ReverseChallenge from "./containers/ReverseChallenge";
import ChildhoodStore from "./containers/ChildhoodStore";
import ReactGA from './components/ReactGaWrapper';
import UserResearchPage from "./containers/UserResearchPage";
import LoveSimulator from "./containers/LoveSimulator";
// import Jielong from "./containers/Jielong";

class Analytics extends React.Component {
  componentDidMount() {
    this.sendPageChange(this.props.location.pathname, this.props.location.search)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname
      || this.props.location.search !== prevProps.location.search) {
      this.sendPageChange(this.props.location.pathname, this.props.location.search)
      window.scrollTo(0, 0);
    }
  }

  sendPageChange(pathname, search) {
    const page = pathname + search
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }

  render() {
    return null
  }
}

const AnalyticsTracker = () => {
  return <Route component={Analytics} />
}


export default function Routes() {
  return (
    <div>
      <AnalyticsTracker />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/rich" exact component={RichSimulator} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/daofang" exact component={ReverseChallenge} />
        <Route path="/childhood" exact component={ChildhoodStore} />
        <Route path="/survey" exact component={UserResearchPage} />
        <Route path="/love" exact component={LoveSimulator} />
        {/* <Route path="/jielong" exact component={Jielong} /> */}
        {/* Redirect all 404's to home */}
        <Redirect to='/' />
      </Switch>
    </div>
  );
}