import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Routes from './Routes';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import './App.css'
import Clipboard from 'react-clipboard.js';
import Header from './components/Header';
import Footer from './components/Footer';

const useStyles = theme => ({
})

const theme = createMuiTheme({
  shadows: [
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
    '0px 0px 0px 0px white,0px 0px 0px 0px white,0px 0px 0px 0px white',
  ],
  palette: {
    primary: amber,
    secondary: {
      main: '#78a929',
      contrastText: '#fff'
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 14,
  },
});


class App extends Component {
  constructor() {
    super();
    this.state = {}

  }

  render() {
    const { classes } = this.props;
    return (
      <div >
        <ThemeProvider theme={theme}>
          <Header></Header>
          <Routes></Routes>
          <Footer></Footer>
        </ThemeProvider>
      </div >
    );
  }
}
export default withStyles(useStyles)(App);