import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { RichSimulatorStyle, MySwitch } from './RichSimulatorStyle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ReactGA from '../components/ReactGaWrapper';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import PublishIcon from '@material-ui/icons/Publish';
import TextAd from '../components/TextAd';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import GitHubButton from 'react-github-btn'
import { Divider } from '@material-ui/core';
import html2canvas from 'html2canvas';

class RichSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.money = 270000000000
    this.yuebaoRate = 0.00000000079
    this.ranking = require('../static/ranking.json')['ranking']

    this.items = require('../static/goods.json')['items'];
    this.items.sort((a, b) => a.price - b.price)
    const itemsNumTmp = {};
    this.items.forEach(item => {
      itemsNumTmp[item.name] = 0;
    });
    let isDisplayTmp = {}
    this.items.forEach(item => {
      isDisplayTmp[item.name] = false;
    });
    this.state = {
      profitPerSec: 0,
      isMakingProfit: false,
      money: this.money,
      balance: this.money,
      itemsNum: itemsNumTmp,
      isDisplay: isDisplayTmp,
      count: 0,
      isDrawerOpen: false,
      isFinish: false,
      imgUrl: null
    };
    this.nametoprice = {}
    this.delay = 1000
    this.items.forEach(item => {
      this.nametoprice[item.name] = item.price;
    });
  }

  getRank = () => {
    for (let i = 0; i < this.ranking.length; i++) {
      if (this.state.balance > this.ranking[i] * 100000000) {
        return i + 1;
      }
    }
    return this.ranking.length + '+ (未上榜)'
  }

  componentDidMount() {
    document.title = "中国首富模拟器，体验花光2700亿-柠檬精LEMONJING";
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  onNumberChange = (target) => {
    const itemname = target.id;
    if (!target.value || target.value == 0) { // 值为空
      target.value = 0;
    } else if (target.value[0] == 0 || target.value[0] == '-') { // 值为负数
      target.value = target.value.substr(1)
    }
    let itemnumber = target.value;

    // 计算物品数量为输入值时的总余额
    let currBalance = this.state.balance - (itemnumber - this.state.itemsNum[itemname]) * this.nametoprice[itemname];

    if (currBalance < 0) {
      // 如果物品数量为输入值时的总余额小于0， 计算当前物品数量余额大于0的情况下的最大值
      let exceptcurr = this.state.balance + this.state.itemsNum[itemname] * this.nametoprice[itemname] // 如果不买当前物品，还剩多少钱
      itemnumber = Math.floor(exceptcurr / this.nametoprice[itemname]);
      target.value = itemnumber;
      currBalance = exceptcurr - this.nametoprice[itemname] * itemnumber
    }

    this.setState({
      count: this.state.count + (itemnumber - this.state.itemsNum[itemname]),
      itemsNum: {
        ...this.state.itemsNum,
        [itemname]: itemnumber
      },
      balance: currBalance,
    })
  }

  handleYuebao = (ismaking) => {
    this.setState({ isMakingProfit: ismaking })
    if (ismaking) {
      this.yuebaoInterval = setInterval(() => {
        // Your custom logic here
        this.makeProfit()
      }, this.delay);
    } else {
      clearInterval(this.yuebaoInterval)
    }
  }
  makeProfit = () => {
    const pps = Math.floor(this.state.balance * this.yuebaoRate)
    this.setState({ balance: this.state.balance + pps, profitPerSec: pps, money: this.state.money + pps });
  }

  toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ isDrawerOpen: open })

    ReactGA.event({
      category: 'rich',
      action: 'toggle drawer'
    })
  }

  handleFinish = () => {
    this.handleYuebao(false)
    this.setState({ isDrawerOpen: false, isFinish: true, imgUrl: null }, () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        html2canvas(document.getElementById('canvas')).then((canvas) => {
          const imgUrl = canvas.toDataURL();
          this.setState({ imgUrl: imgUrl });
        })
      }, 1500)
    })
    window.scrollTo(0, 0);
    ReactGA.event({
      category: 'rich',
      action: 'richfinish'
    })
  }

  handleClear = () => {
    const itemsNumTmp = {};
    this.items.forEach(item => {
      itemsNumTmp[item.name] = 0;
    });
    this.setState({ itemsNum: itemsNumTmp, balance: this.state.money, count: 0 })
  }

  render() {
    const { isMakingProfit, profitPerSec, balance, itemsNum, isFinish, money } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.root} >
        <ScrollUpButton ContainerClassName={classes.scrollUpButton} TransitionClassName={classes.scrollUpTrans}><PublishIcon color='secondary' /></ScrollUpButton>
        <SwipeableDrawer
          anchor="right"
          open={this.state.isDrawerOpen}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <Grid container justify="center">
            <List className={classes.shopList}>
              <ListSubheader component="div" id="nested-list-subheader">
                我的购物车 - 首富模拟器(lemonjing.com/rich)
              </ListSubheader>
              <div style={{ textAlign: 'center' }}>
                <Button aria-label="清空购物车" onClick={this.handleClear} variant='outlined' color='primary' disabled={this.state.count === 0}>清空购物车</Button>&emsp;
                <Button aria-label="确认下单" onClick={this.handleFinish} variant='contained' color='primary' disabled={this.state.count === 0}>确认下单</Button>
              </div>
              {
                this.state.count === 0 ? <Typography className={classes.emptyLabel}>你的购物车空空如也</Typography> : null
              }
              {
                this.items.map(item => (itemsNum[item.name] == 0 ? null :
                  <ListItem key={item.name}>
                    <ListItemAvatar>
                      <Avatar src={require(`../static/images/items/${item.name}.jpg`)}></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body2">{item.name}</Typography>} secondary={'数量: ' + itemsNum[item.name]} style={{ marginRight: '6px' }} />
                    <ButtonGroup color="secondary" size="small" aria-label="outlined primary button group">
                      <Button onClick={e => {
                        const target = document.getElementById(item.name);
                        target.value = 0;
                        this.onNumberChange(target)
                      }}>删除</Button>
                      <Button onClick={e => {
                        const target = document.getElementById(item.name);
                        target.value = parseInt(document.getElementById(item.name).value) - 1;
                        this.onNumberChange(target)
                      }}>-</Button>
                      <Button onClick={e => {
                        const target = document.getElementById(item.name);
                        target.value = parseInt(document.getElementById(item.name).value) + 1;
                        this.onNumberChange(target)
                      }}>+</Button>
                    </ButtonGroup>
                  </ListItem>
                ))
              }
              <Typography className={classes.emptyLabel}>剩余￥{this.numberWithCommas(balance)}元</Typography>
            </List>
          </Grid>

        </SwipeableDrawer>
        <Grid container justify='space-between' alignItems="center" className={classes.appbar}>
          <Grid item>
            <Typography variant="h5" className={classes.balance}>
              ￥{this.numberWithCommas(balance)}
            </Typography>
            <Typography variant="body1" className={classes.balance}>
              中国富豪榜排名：{this.getRank()}
            </Typography>
          </Grid>
          <Grid item>
            {(
              isFinish ?
                <Button onClick={() => this.setState({ isFinish: false })}>返回购物</Button>
                :
                <IconButton aria-label="cart" onClick={this.toggleDrawer(true)}>
                  <Badge badgeContent={this.state.count} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
            )}
            {/* <Button variant='outlined' color='secondary'>买了什么</Button> */}
          </Grid>
        </Grid>
        <main>
          <Container className={classes.container} maxWidth="md">
            {/* <div style={{ textAlign: 'center' }}>
              <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '100%', height: 100, margin: '10px auto' }}
                data-ad-client="ca-pub-5404682594654148"
                data-ad-slot="6892092047">
              </ins>
            </div> */}
            <TextAd></TextAd>
            {(
              isFinish ?
                <div>
                  <Typography color='textSecondary' align='center'>注：本页面内容纯属娱乐，并非真实支付购买</Typography>
                  <br />
                  {/* <Paper className={classes.paySuccess}>
                    <Grid container justify="center" alignItems="center">
                      <Box className={classes.wordsWrapper}>
                        <Typography variant="h3" className={classes.largewords}>支付成功</Typography>
                        <Typography variant="h5"><b>￥{this.numberWithCommas(this.state.money - this.state.balance - 0.26)}</b></Typography>
                        <Typography variant="subtitle1" color='textSecondary' style={{ textDecoration: 'line-through' }}>￥{this.numberWithCommas(this.state.money - this.state.balance)}</Typography>
                        <br />
                        <List component="nav" aria-label="secondary mailbox folders">
                          <ListItem>
                            <ListItemText primary="余额" />
                            <ListItemSecondaryAction>
                              -{this.numberWithCommas(this.state.money - this.state.balance)}
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Box>
                    </Grid>
                  </Paper> */}
                  {/* <div className={classes.shopList}>
                    <Button component='a' href={this.state.imgUrl} download="首富模拟器" variant='contained' target='_self' variant='contained' color='secondary'>保存为图片😎</Button>
                  </div> */}
                  <Typography color='textSecondary' align='center'>长按保存图片👇</Typography>
                  <br />
                  <div className={classes.finishWrapper} id="canvas">
                    {(this.state.imgUrl !== null ? <img src={this.state.imgUrl} style={{ position: 'absolute', left: 0, top: 0, zIndex: 10, width: '100%' }} /> : null)}
                    <div style={{ textAlign: 'center' }}>
                      <img src={require('../static/images/首富模拟器.png')} style={{ width: '100%', maxWidth: 360 }} />
                    </div>
                    <Typography align='center'>这里竟然能体验首富的感觉</Typography>
                    <Typography align='center'>资产有<b>2700亿</b>，我花了<b>{this.numberWithCommas(money - balance)}</b>块</Typography>
                    <Typography align='center'>还剩<b>{this.numberWithCommas(balance)}</b>块</Typography>
                    {(typeof this.getRank() === 'number' ? <Typography align='center'>剩下的财产竟然还在中国排名第<b>{this.getRank()}</b>名</Typography> : null)}
                    {(money - this.money > 0 ? <Typography align='center'>存在余额宝一会就赚了<b>{money - this.money}</b>块</Typography> : null)}
                    <Typography align='center'>花钱真的太累了！</Typography>
                    <div style={{ textAlign: 'center' }}>
                      <img src={require('../static/images/首富二维码.png')} style={{ width: '100%', maxWidth: 360 }} />
                    </div>
                    <Paper className={classes.shopList}>
                      <List>
                        <ListSubheader component="div" id="nested-list-subheader">
                          我的订单
                    </ListSubheader>
                        <Divider />
                        {
                          this.state.count === 0 ? <Typography className={classes.emptyLabel}>你没有订单</Typography> : null
                        }
                        {
                          this.items.map(item => (itemsNum[item.name] == 0 ? null :
                            <ListItem key={item.name}>
                              <ListItemAvatar>
                                <Avatar src={require(`../static/images/items/${item.name}.jpg`)}></Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={<Typography variant="body2">{item.name}</Typography>} secondary={'￥' + this.nametoprice[item.name] + ' × ' + itemsNum[item.name]} style={{ marginRight: '6px' }} />
                              <div>
                                <Typography variant="body2" align='right'>合计:</Typography>
                                <Typography variant="body2" align='right'>￥{this.numberWithCommas(this.nametoprice[item.name] * itemsNum[item.name])}</Typography>
                              </div>
                            </ListItem>
                          ))
                        }
                        <Typography className={classes.emptyLabel}>总计消费￥{this.numberWithCommas(money - balance)}元</Typography>
                        <Typography className={classes.emptyLabel}>余额￥{this.numberWithCommas(balance)}元</Typography>
                      </List>
                    </Paper>
                  </div>
                  <br />
                </div>
                :
                <div>
                  <Paper className={classes.topPaper}>
                    <Grid container justify="center" alignItems="center">
                      <Box className={classes.wordsWrapper}>
                        <Typography variant="h3" className={classes.largewords}>首富模拟器</Typography>
                        <Typography>在首富模拟器里，你是拥有有两千七百亿资产的中国首富，买起来吧！</Typography>
                        <br />
                        <Typography variant="body2" color="textSecondary">本页内容纯属虚构，仅供图一乐。 </Typography>
                        <Typography variant="body2" color="textSecondary">受到<a href="https://neal.fun/spend/">Spend Bill Gates' Money</a>的启发</Typography>
                        <GitHubButton href="https://github.com/liust97/jackma-simulator" data-show-count="true" aria-label="Star liust97/jackma-simulator on GitHub">Github</GitHubButton>
                      </Box>
                    </Grid>
                  </Paper>
                  <Paper className={classes.yuebaoPaper}>
                    <FormControlLabel
                      className={classes.yuebaoLabel}
                      control={
                        <MySwitch
                          checked={isMakingProfit}
                          onChange={(event) => this.handleYuebao(event.target.checked)}
                          value="checkedB"
                        />
                      }
                      label="把钱存入余额宝"
                    />

                    {(isMakingProfit ? <span>每秒收益：{profitPerSec}元</span> : null)}

                  </Paper>
                  <Typography className={classes.smallText}>(品牌、人物仅用于举例，与本站无关；物品价格仅供参考)</Typography>
                  <Grid className={classes.cardGrid} container spacing={2}>
                    {
                      this.items.map(item => (
                        <Grid item key={item.name} xs={6} sm={4} md={3}>
                          <Card className={classes.card}>
                            <CardMedia
                              className={classes.cardMedia}
                              image={require(`../static/images/items/${item.name}.jpg`)}
                              title={item.name}
                            />
                            <CardContent className={classes.cardContent}>
                              <Typography component="h2">
                                {item.name}
                              </Typography>
                              <Typography className={classes.price}>
                                ￥<b>{this.numberWithCommas(item.price)}</b>
                              </Typography>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                              <TextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                id={item.name}
                                data-itemname={item.name}
                                label="数量"
                                type="number"
                                value={itemsNum[item.name]}
                                className={classes.cardInput}
                                margin="normal"
                                inputProps={{ min: "0", step: "1" }}
                                onChange={(e) => this.onNumberChange(e.target)}
                              />
                              <Button id={item.name} className={classes.addButton} size="small" variant="contained" color="primary" disabled={(balance < item.price)}
                                onClick={e => {
                                  const target = document.getElementById(item.name);
                                  target.value = parseInt(document.getElementById(item.name).value) + 1;
                                  this.onNumberChange(target)
                                }}>
                                加入购物车
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))
                    }
                  </Grid>
                  <div style={{ textAlign: 'center' }}>
                    {/* <Button aria-label="cart" onClick={this.toggleDrawer(true)} color='primary'>
                      <Badge badgeContent={this.state.count} color="secondary">
                        查看购物车
                      </Badge>
                    </Button> */}
                    <Button aria-label="确认下单" onClick={this.handleFinish} variant='contained' color='primary' disabled={this.state.count === 0}>确认下单</Button>
                  </div>
                  <br />
                  <Typography color='textSecondary'>注：本页面内容纯属娱乐，并非真实支付购买</Typography>
                </div>
            )}
            {/* <div style={{ textAlign: 'center' }}>
              <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '100%', height: 100, margin: '10px auto' }}
                data-ad-client="ca-pub-5404682594654148"
                data-ad-slot="4619154058">
              </ins>
            </div> */}
            <hr />
            <Card className={classes.card}>
              <CardActionArea component={Link} to="/childhood" style={{ textDecoration: 'none' }} disableRipple>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2" className={classes.webTitle}>
                    童年模拟器
                </Typography>

                  <Typography variant="body2" color="textSecondary" component="p">
                    2700亿元太少？其实小时候，100元就已经很多。
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Container>
        </main>
      </div >
    );
  }

}

export default withStyles(RichSimulatorStyle)(RichSimulator);