import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = theme => ({
    root: {
        width: 320,
        height: 140,
        backgroundImage: "url(" + require("../static/images/gamepad.png") + ")",
        position: "relative",
        display: "inline-block"
    },
    arrowButton: {
        width: 24,
        height: 24,
        position: "absolute",
        backgroundColor: "#121313",
        color: "#fff"
    },
    roundButton: {
        width: 28,
        height: 28,
        borderRadius: "50%",
        position: "absolute",
        backgroundColor: "#c60101",
        color: "#fff"
    }
})

class Reverser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.ans = "uuddlrlrba"
        this.str = ""
        this.clickAudio = new Audio(require('../static/audios/按键.mp3'))
        this.successAudio = new Audio(require('../static/audios/魂斗罗.mp3'))
    }
    handleButtonClick = key => {
        this.str += key;
        this.clickAudio.currentTime = 0
        this.clickAudio.play()
        if (this.str.length > this.ans.length) {
            this.str = this.str.substr(this.str.length - this.ans.length, this.ans.length)
        }
        if (this.str === this.ans) {
            this.successAudio.currentTime = 0
            this.successAudio.play()
            this.props.onSuccess();
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <button className={classes.arrowButton} onClick={() => this.handleButtonClick('u')} style={{left: 44, top: 52}}>
                    ↑
                </button>
                
                <button className={classes.arrowButton} onClick={() => this.handleButtonClick('l')} style={{left: 23, top: 72}}>
                    ←
                </button>
                
                <button className={classes.arrowButton} onClick={() => this.handleButtonClick('d')} style={{left: 44, top: 93}}>
                    ↓
                </button>
                <button className={classes.arrowButton} onClick={() => this.handleButtonClick('r')} style={{left: 65, top: 72}}>
                    →
                </button>
                <button className={classes.roundButton} onClick={() => this.handleButtonClick('b')} style={{left: 210, top: 85}}>
                    B
                </button>
                <button className={classes.roundButton} onClick={() => this.handleButtonClick('a')} style={{left: 252, top: 85}}>
                    A
                </button>
            </div>
        );
    }
}
export default withStyles(useStyles)(Reverser);

Reverser.defaultProps = {
    onSuccess: () => console.log('success')
}