import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import * as Scroll from 'react-scroll';
import ImageUploader from 'react-images-upload';
import '../static/styles/wechat.css'
import { MySwitch } from './RichSimulatorStyle';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = theme => ({
    switch: {

    }
})
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class LoveSimulator extends Component {
    constructor() {
        super();
        this.state = {
            message: "我喜欢你！",
            queue: [],
            editing: false,
            sendable: true,
            open: true,
            leftPictures: [require('../static/images/wechat/girl.png')],
            rightPictures: [require('../static/images/wechat/boy.png')],
            isRepeat: false
        }
    }
    componentDidMount() {
        document.title = "表白模拟器-柠檬精LEMONJING";
    }
    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.sendMessage()
        }
    }
    scrollToBottom = () => {
        Scroll.scroller.scrollTo('bottom', {
            duration: 100,
            smooth: true,
            containerId: 'chat',
        })
    }
    sendMessage = () => {
        if (this.state.message.length > 0 && this.state.sendable) {
            const lastmsg = this.state.message
            this.setState({ queue: [...this.state.queue, { left: false, content: lastmsg }], message: '', sendable: false },
                () => {
                    this.scrollToBottom()
                    if (lastmsg.includes('我喜欢你')) {
                        setTimeout(() => { // 半秒后开始打字
                            this.setState({ editing: true }, () => {
                                let newmsg = lastmsg.replace(/我喜欢你/g, '我也喜欢你')
                                let timeout = 100 * newmsg.length
                                if (timeout < 1000) {
                                    timeout = 1000 + Math.random() * 500
                                }
                                if (timeout > 5000) {
                                    timeout = 5000
                                }
                                setTimeout(() => { // 打一段时间的字
                                    const addlist = ['😘', '🥰🥰🥰', '😆😆😆', '嘻嘻...', '真的吗..？', '🥰那太好了！', '好巧哦！', '我知道啦   ', '呀！',
                                    ]
                                    if (Math.random() < 0.6) {
                                        newmsg = addlist[Math.floor(Math.random() * addlist.length)] + newmsg
                                    }
                                    this.setState({ queue: [...this.state.queue, { left: true, content: newmsg }], sendable: true, editing: false })
                                    this.scrollToBottom()
                                }, timeout)
                            })
                        }, 500)
                    } else if (this.state.isRepeat) {
                        setTimeout(() => { // 半秒后开始打字
                            this.setState({ editing: true }, () => {
                                let timeout = 80 * lastmsg.length
                                if (timeout < 1000) {
                                    timeout = 1000 + Math.random() * 500
                                }
                                if (timeout > 5000) {
                                    timeout = 5000
                                }
                                setTimeout(() => { // 打一段时间的字
                                    this.setState({ queue: [...this.state.queue, { left: true, content: lastmsg }], sendable: true, editing: false })
                                    this.scrollToBottom()
                                }, timeout)
                            })
                        }, 500)
                        
                    }
                    else {
                        this.setState({ sendable: true })
                    }
                })
        }
    }
    replyMessage = () => {
        
    }
    onMessageChange = (e) => {
        this.setState({ message: e.target.value })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    handleOpen = () => {
        this.setState({ open: true })
    }
    onLeftDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            leftPictures: [pictureDataURLs[0]],
        });
    }
    onRightDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            rightPictures: [pictureDataURLs[0]],
        });
    }
    handleRepeatChange = event => {
        this.setState({ isRepeat: event.target.checked });
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <section className="chat-page">
                    <header>
                        <Link to='/' className="nav-back">
                            <i className="icon icon-back"></i>
                        </Link>
                        <h1 hidden={!this.state.editing}>正在输入中...</h1>
                        <h1 hidden={this.state.editing} contenteditable="true">小白</h1>
                        <div className="nav-person" onClick={this.handleOpen}>
                            <i className="icon icon-person"></i>
                        </div>
                    </header>
                    <main id="chat">
                        <div className="message-list">
                            {
                                this.state.queue.map((msg, index) =>
                                    (msg.left ?
                                        <div className="message-item message-item--left" key={index}>
                                            <img className="avatar" src={this.state.leftPictures[0]} alt="头像" onClick={this.handleOpen} />
                                            <div className="message-bubble">{msg.content}</div>
                                        </div>
                                        :
                                        <div className="message-item message-item--right" key={index}>
                                            <img className="avatar" src={this.state.rightPictures[0]} alt="头像" onClick={this.handleOpen} />
                                            <div className="message-bubble">{msg.content}</div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                        <Scroll.Element name="bottom"></Scroll.Element>
                    </main>
                    <footer>
                        <i className="icon icon-voice"></i>
                        <input type="text" className="text-input" value={this.state.message} onChange={this.onMessageChange} onKeyDown={this.handleKeyDown} />
                        <i className="icon icon-face"></i>
                        <button className="send-button" onClick={this.sendMessage}>发送</button>
                        {/* <i className="icon icon-plus"></i> */}
                    </footer>
                </section>

                <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}
                    TransitionComponent={Transition}>
                    <DialogTitle id="simple-dialog-title">表白模拟器 lemonjing.com/love</DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            更多内容请到 <Link to="/" style={{ textDecoration: 'none' }} ><Button color="primary" size="small" variant="outlined">LEMONJING主页</Button></Link>
                        </DialogContentText>
                        <DialogContentText>
                            这里可以让你体验表白成功的感觉。<b>发送的文字包含“我喜欢你”，对方才会回复。原理是将“我喜欢你”替换成“我也喜欢你”</b>
                        </DialogContentText>
                        {/* <Typography>现实中并不是每一句“我喜欢你”都能换来一句“我也喜欢你”</Typography> */}
                        
                        <FormControlLabel
                            control={
                                <MySwitch checked={this.state.isRepeat} onChange={this.handleRepeatChange} inputProps={{ 'aria-label': '复读机' }} />
                            }
                            label="复读机（重复你说的话）"
                        />

                        <DialogContentText>
                            点击上方的名字就可以直接编辑对方的名字。在下面可以修改自己和对方的头像。也可以使用默认头像。
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ImageUploader
                                    withIcon={false}
                                    buttonText='对方头像'
                                    onChange={this.onLeftDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.webp']}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ImageUploader
                                    withIcon={false}
                                    buttonText='自己头像'
                                    onChange={this.onRightDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.webp']}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                />
                            </Grid>
                        </Grid>
                        <DialogContentText>
                            欢迎将这个网站分享给朋友。
                        </DialogContentText>
                        <DialogContentText>
                            点击<b>右上角的按钮</b>可以再次打开这个面板。
                        </DialogContentText>

                        <DialogActions>
                            <Button onClick={this.handleClose} color="secondary" variant="outlined">
                                好的
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}
export default withStyles(useStyles)(LoveSimulator);