import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

const useStyles = theme => ({
    footer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: '#fff',
      textAlign: 'center',
      color: '#febd00',
    },
    footerText: {
      fontSize: '80%',
      letterSpacing: '0.2em',
      margin: '0.2em',
    },
    smallText: {
      fontSize: '60%',
      letterSpacing: '0.2em',
      margin: '0.2em',
      color: '#6b6b6b'
    },
    footerGrid: {
      marginBottom: -8
    },
    qrcodeImg: {
      width: '3rem',
      margin: theme.spacing(1),
    },
    popText: {
      letterSpacing: '0.1em',
      fontSize: '70%',
      textAlign: 'center',
    },
    collectImg: {
      height: '20rem'
    },
    collectFrame: {
      height: '20rem'
    }
})

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { classes } = this.props;
        return (
            <footer className={classes.footer}>
              <Container maxWidth='md'>
                <Grid container justify="center" alignItems="center" spacing={2}>
                  <Grid item className={classes.footerGrid}>
                    <Typography className={classes.footerText}>
                      <Link to="/" style={{ textDecoration: 'none' }} ><Button color="primary" size="small" variant="outlined">LEMONJING主页</Button></Link>
                      &nbsp;|&nbsp;由<a href="https://www.weibo.com/u/3228472547">@LiuST</a>不自豪地制作 &nbsp;
  
                    </Typography>
                    <Typography className={classes.footerText}> <a href="mailto:liust97@qq.com">邮件</a> <Link to="/terms">条款</Link> <a href="https://www.freepik.com/">部分图源Freepik</a></Typography>
                  </Grid>
  
                </Grid>
              </Container>
            </footer>
        );
    }
}
export default withStyles(useStyles)(Header);