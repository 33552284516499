// components/waveform.js
import React from 'react'
import ReactDOM from 'react-dom'
import WaveSurfer from 'wavesurfer.js'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Button from '@material-ui/core/Button';
import PauseIcon from '@material-ui/icons/Pause';

export default class Waveform extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isplaying: false
        }
    }
    componentDidMount() {
        this.$el = ReactDOM.findDOMNode(this)
        this.$waveform = this.$el.querySelector('.wave')
        this.wavesurfer = WaveSurfer.create({
            barWidth: 2,
            container: this.$waveform, ...this.props.wavepara
        })
        this.wavesurfer.load(this.props.src)
        this.wavesurfer.on('finish', () => {
            this.setState({
                isplaying: false
            })
        });
        // this.wavesurfer.setPlaybackRate(-1)
    }
    componentWillReceiveProps(nextProps) {
        this.wavesurfer.load(nextProps.src)
    }
    playPauseAudio = () => {
        this.wavesurfer.setPlaybackRate(1)
        this.wavesurfer.playPause()
        this.setState({
            isplaying: !this.state.isplaying
        })
    }
    slowPlayPauseAudio = () => {
        this.wavesurfer.setPlaybackRate(0.5)
        this.wavesurfer.playPause()
        this.setState({
            isplaying: !this.state.isplaying
        })
    }
    componentWillUnmount() {

    }
    render() {
        return (
            <div className='waveform'>
                <div className='wave'></div>
                <Button style={{ margin: 8 }} size="small" variant={(this.props.reverseHighlight) ? "contained" : "outlined"} color="secondary"
                    onClick={this.playPauseAudio}>{this.props.buttonname}
                    {(this.state.isplaying) ? <PauseIcon /> : <PlayArrowIcon />}
                </Button>
                {
                    (this.props.slowbuttonname) ?
                        <Button style={{ margin: 8 }} size="small" variant={(this.props.reverseHighlight) ? "contained" : "outlined"} color="secondary"
                            onClick={this.slowPlayPauseAudio}>{this.props.slowbuttonname}
                            {(this.state.isplaying) ? <PauseIcon /> : <PlayArrowIcon />}
                        </Button>
                        :
                        null
                }

            </div>
        )
    }
}

Waveform.defaultProps = {
    src: "",
    wavepara: {},
    buttonname: "",
    reverseHighlight: false
}