import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { Container, Paper, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import ReactGA from '../components/ReactGaWrapper';
import Grid from '@material-ui/core/Grid';
import TextAd from '../components/TextAd';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = theme => ({
    cardGrid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    card: {
        height: '100%'
    },
    media: {
        paddingTop: '70%', // 16:9
    },
    mainWrapper: {
        backgroundColor: "#e8efdd",
        textAlign: "center",
    },
    content: {
        paddingTop: theme.spacing(1)
    },
    superWrapper: {
        maxWidth: 600,
        margin: 'auto'
    },
    imgWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '32%'
    },
    mainImg: {
        width: "100%",
    },
    about: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex'
    },
    aboutLeft: {
        flex: '0 0 70px'
    },
    aboutRight: {

    },
    largeAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        border: '5px solid #e8f3d7'
    },
    webTitle: {
        color: '#78a929'
    },
    linksWrapper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paperTitle: {
        margin: 3,
    },
    paperContent: {
        padding: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.75)'
    },
    footerGrid: {
        marginBottom: -8
    },
    qrcodeImg: {
        width: '5rem',
        margin: theme.spacing(1),
    },
    popText: {
        letterSpacing: '0.1em',
        fontSize: '70%',
        textAlign: 'center',
    },
})
class Home extends Component {
    constructor() {
        super();
        this.state = {
            sourceText: '',
            alertOpen: false
        }

    }
    componentDidMount() {
        document.title = "柠檬精LEMONJING - 首富模拟器,童年模拟器 - 用酸创造快乐";
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    handleSourceSubmit = () => {

        ReactGA.event({
            category: 'survey',
            action: 'submit',
            label: this.state.sourceText
        })
        this.setState({ alertOpen: true, sourceText: '' })
    }
    handleSourceChange = event => {
        this.setState({ sourceText: event.target.value })
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.mainWrapper}>
                    <Container maxWidth="md">
                        <div className={classes.superWrapper}>
                            <div className={classes.imgWrapper}>
                                <img alt="用酸创造快乐" className={classes.mainImg} src={require("../static/images/home_main.png")}></img>
                            </div>
                        </div>
                    </Container>
                </div>
                <Container maxWidth="md" >
                    <Grid container spacing={2} className={classes.cardGrid}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to="/rich" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardMedia
                                        className={classes.media}
                                        image={require("../static/images/cards/money.jpg")}
                                        title="money"
                                    />
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            首富模拟器
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            体验首富的感觉，花光两千七百亿元。
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to="/childhood" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardMedia
                                        className={classes.media}
                                        image={require("../static/images/cards/candy.jpg")}
                                        title="candy"
                                    />
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            童年模拟器
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            穿越回到童年，回味千禧年代的共同回忆。
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to="/daofang" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardMedia
                                        className={classes.media}
                                        image={require("../static/images/cards/mic.jpg")}
                                        title="倒放挑战图片"
                                    />
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            倒放挑战
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            首个在线倒放挑战网站。（千万别念“一把石手”）
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to="/love" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardMedia
                                        className={classes.media}
                                        image={require("../static/images/cards/love.jpg")}
                                        title="love"
                                    />
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            表白模拟器
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            情人节特别推出，表白百分百成功！
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Card>
                                <CardActionArea component='a' href="https://www.zhihu.com/question/354444082/answer/1036999931" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            关于本站的介绍
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            「你都见过什么奇奇怪怪的网站？」我在这篇知乎回答介绍了这个网站。不妨点个赞，谢谢👍
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    <TextAd />
                    {/* <ins className="adsbygoogle"
                        style={{ display: 'inline-block', width: '100%', height: 100, margin: '10px auto' }}
                        data-ad-client="ca-pub-5404682594654148"
                        data-ad-slot="9737268302">
                    </ins> */}
                    {/* {(
                        document.referrer === "" ?
                            <Paper className={classes.linksWrapper}>
                                <form className={classes.form} noValidate autoComplete="off">
                                    <Grid container justify="center" alignItems="center" spacing={2}>
                                        <Grid item xs={9} sm={9}>
                                            <TextField onChange={this.handleSourceChange} value={this.state.sourceText} style={{ width: '100%' }} label="您从哪里知道这个网站？" size="small" variant="outlined" />
                                        </Grid>
                                        <Grid item xs={3} sm={3}>
                                            <Button type="button" onClick={this.handleSourceSubmit} variant="contained" color="primary" disabled={this.state.sourceText.length === 0}>提交</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Typography variant="caption">例如：抖音xx博主，微信xx公众号等。</Typography>
                            </Paper> : null
                    )} */}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }} open={this.state.alertOpen} autoHideDuration={2000} onClose={() => { this.setState({ alertOpen: false }) }} message="提交成功，谢谢！">

                    </Snackbar>
                    {/* <UserResearch /> */}
                    <Paper className={classes.linksWrapper}>
                        <Typography className={classes.paperTitle}>友情链接<span style={{ color: '#555', fontSize: '80%' }}> （欢迎<a href="mailto:liust97@qq.com">交换友链</a>）</span></Typography>
                        <Divider />
                        <List dense={true}>
                            <ListItem button component="a" href="https://shadiao.pro/" target="_blank" onClick={() => {
                                ReactGA.event({
                                    category: 'links',
                                    action: 'shadiao app'
                                })
                            }}>
                                <ListItemAvatar>
                                    <Avatar variant="rounded" alt="沙雕APP logo" src={require("../static/images/links/shadiao_icon.png")} />
                                </ListItemAvatar>
                                <ListItemText primary="「沙雕APP」系列作品" secondary="骂人宝典 / 彩虹屁生成器 / 毒鸡汤" />
                            </ListItem>
                            {/* <ListItem button component="a" href="https://loser886.cn/" target="_blank" onClick={() => {
                                ReactGA.event({
                                    category: 'links',
                                    action: 'loser886'
                                })
                            }}>
                                <ListItemAvatar>
                                    <Avatar variant="rounded" alt="loser886" src={require("../static/images/links/loser_icon.png")} />
                                </ListItemAvatar>
                                <ListItemText primary="loser886" secondary="导航中的导航，学习摸鱼两不误" />
                            </ListItem> */}
                        </List>
                    </Paper>
                    <Paper className={classes.linksWrapper}>
                        <Typography className={classes.paperTitle}>关于</Typography>
                        <Divider />
                        <div className={classes.paperContent}>
                            <Typography variant='body2'>本站是我的无聊网页作品合集。</Typography>
                            <br />
                            <Typography variant='body2'>您只需在浏览器访问www.lemonjing.com就可以进入这个网站，不需要下载任何程序。
                            任何的APP与本站无关，并可能危害您的个人信息安全，敬请留意。</Typography>
                            <br />
                            <Typography variant='body2'><a href="http://lemonjing.mikecrm.com/PEMWhlu">点击这里</a>向我留言（提出建议/反馈网站问题/其他）。</Typography>
                            <br />
                            <Grid item className={classes.footerGrid}>
                                <Typography variant='body2' align='center'><b>作者</b></Typography>
                                <Grid container alignItems="center" justify="center" >
                                    {/* <Grid item>
                                        <Typography className={classes.popText}>关</Typography>
                                        <Typography className={classes.popText}>注</Typography>
                                        <Typography className={classes.popText}>作</Typography>
                                        <Typography className={classes.popText}>者</Typography>
                                    </Grid> */}
                                    <Grid item>
                                        <a href="https://www.zhihu.com/people/qian-zhui-6">
                                            <img alt="微博头像" src={require('../static/images/cards/zhihu.jpg')} className={classes.qrcodeImg} />
                                            <Typography className={classes.popText}>知乎</Typography>
                                        </a>
                                    </Grid>
                                    {/* <Grid item>
                                        <a href="https://www.weibo.com/u/3228472547">
                                            <img alt="微博头像" src={require('../static/images/cards/weibo.jpg')} className={classes.qrcodeImg} />
                                            <Typography className={classes.popText}>微博</Typography>
                                        </a>
                                    </Grid> */}
                                    <Grid item>
                                        <img alt="公众号二维码" src={require('../static/images/cards/qrcode.jpg')} className={classes.qrcodeImg} />
                                        <Typography className={classes.popText}>公众号</Typography>
                                    </Grid>
                                    {/* <Grid item><a href="https://liust.me">
                                        <img alt="主页ico" src={require('../static/images/cards/liust.me.png')} className={classes.qrcodeImg} />
                                        <Typography className={classes.popText}>个人主页</Typography>
                                    </a>
                                    </Grid> */}
                                </Grid>
                                <br />
                                {/* <Typography variant='body2' align='center' id='like'><b>打赏作者(微信)</b></Typography>
                                <Typography variant='body2' align='center' id='like'>本站目前无广告，欢迎大家打赏支持。</Typography>
                                <div style={{ textAlign: 'center' }}><img src={require('../static/images/wechat_sponsor.jpg')} style={{ maxWidth: '100%' }} /></div> */}

                            </Grid>
                            {/* <br/>
                            <Typography variant="body2">
                                喜欢这个
                            </Typography> */}
                        </div>
                    </Paper>
                </Container>
            </div >
        );
    }
}
export default withStyles(useStyles)(Home);